import { Platform } from 'react-native'

import { academicsApiUrl, awsDomain, baseUrl } from './src/config'

const awsRegion = 'ap-south-1'

const config = {
  Auth: {
    identityPoolId: `${awsRegion}:cf3374b6-935f-4ef5-8bb4-1b990a584c46`,
    region: awsRegion,
    userPoolId: `${awsRegion}_5MvwY0cKi`,
    userPoolWebClientId: '40jdsleqa3gfvr0tl5bcbp4vg9',
    oauth: {
      domain: awsDomain,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: Platform.OS === 'web' ? baseUrl : 'camped://',
      redirectSignOut:
        Platform.OS === 'web' ? `${baseUrl}/logout` : 'camped://',
      responseType: 'token',
    },
    cookieStorage: {
      domain: '.web.camped.academy',
      path: '/',
      expires: 7,
      secure: false,
      sameSite: 'lax',
    },
  },
  API: {
    endpoints: [
      {
        name: 'Academics',
        endpoint: academicsApiUrl,
        region: awsRegion,
      },
    ],
  },
}

export default config
