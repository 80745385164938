export const stage = 'web'
export const lmsDomain = 'lms.camped.academy'
export const awsDomain = `auth.camped.academy`
export const domainURL = 'https://www.camped.academy'

export const mailTo =
  'mailto:support@camped.academy?subject=SendMail&body=Description'

export const privacyPolicyUrl = `${domainURL}/privacy-policy`
export const termsConditionUrl = `${domainURL}/terms-and-conditions`
export const increscoUrl = 'https://increscotech.com/'
export const sentryUrl =
  'https://o4505707878154240.ingest.sentry.io/4505720798183424'

export const baseUrl = `https://${stage}.camped.academy`

export const lmsUrl = `https://apps.${lmsDomain}/learning/course/`
export const lmsStudioUrl = `https://studio.${lmsDomain}/course/`
export const acePrepUrl = 'https://aceprep.camped.academy'

export const apiUrl = `https://dev-api.camped.academy/graphql`
export const academicsApiUrl = `https://api.camped.academy/graphql`
export const marketPlaceUrl = `https://camped-ecommerce-frontend.vercel.app`

export const socialUrl = 'https://camped-social.vercel.app'
export const feedsUrl = 'https://dev-outreach.camped.academy'
