import { useNavigationState } from '@react-navigation/native'
import { useAtom } from 'jotai'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'

import { API } from '@camped/api'
import { Text } from '@camped/components'
import useValidation from '@camped/core/src/hooks/useValidation'
import { domainAtom } from '@camped/jotai-storage'
import { ScreenLayout, SecureStore } from '@camped/utils'

import useCreateUserMutation from '../hooks/use-create-user'
import { useGetListing } from '../hooks/use-get-listing'
import useOnChangeText from '../hooks/use-on-change-text'
import { useUpdateUserDetails } from '../hooks/use-update-user-detail'
import useUploadDocument from '../hooks/use-upload-document'
import useUploadImage from '../hooks/use-upload-image'
import { groupMetaData } from '../utils/group-meta-data'
import DesktopView from './Desktop-view'
import MobileView from './Mobile-view'

const ViewDetails = (props) => {
  const { t } = useTranslation()
  const { route, module } = props
  const { id } = route?.params || ''
  const { firstName } = route?.params || ''
  const [addSection, setAddSection] = useState('')
  const [attachment, setAttachment] = useState(null)
  const [checked, setChecked] = useState({})
  const [editSection, setEditSection] = useState('')
  const [selectedSem, setSelectedSem] = useState(1)
  const [errors, setErrors] = useState([])
  const [tabSelect, setTabSelect] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTopics, setselectedTopics] = useState([])
  const [domainDetails] = useAtom(domainAtom)
  const [selectedUnitsValidation, setSelectedUnitsValidation] = useState('')
  const [user, setUser] = useState({
    countryCode: { value: 'IN', metadata: '' },
    callingCode: { value: '+91', metadata: '' },
  })
  const [updateValue, setUpdateValue] = useState({
    fields: [],
  })
  const detailsRef = useRef(null)
  const [filterDegree, setFilterDegree] = useState('')
  const [filterCourse, setFilterCourse] = useState('')

  useEffect(() => {
    if (user?.graduation?.value && (editSection || !id)) {
      setUser((prevUser) => ({
        ...prevUser,
        degree: { value: '', metadata: '' },
      }))
    }
  }, [user?.graduation?.value])
  useEffect(() => {
    if (user?.department?.value && (editSection || !id)) {
      setUser((prevUser) => ({
        ...prevUser,
        course: { value: '', metadata: '' },
      }))
    }
  }, [user?.department?.value])

  const scrollToTop = () => {
    if (detailsRef.current) {
      detailsRef.current.scrollTo({ y: 0, animated: true })
    }
  }
  console.log()
  const { fileInputRef, getBase64, handleAddImage, uploadImage } =
    useUploadImage()
  const { pickPDF, handleFileChange, pdfInputRef } = useUploadDocument()
  const showSideBar = [
    'view-student-details',
    'view-staff-details',
    'my-profile',
  ]
  const currentRoute = useNavigationState(
    (state) => state?.routes?.[state.routes.length - 1],
  )?.name

  useEffect(() => {
    if (!attachment) return
    ;(async () => {
      await uploadImage(id, module, attachment)
    })()
  }, [attachment])

  const onUpload = async (text) => {
    handleAddImage()
    getBase64(text).then((file) => {
      setAttachment({
        encode: file,
        file: text,
      })
    })
  }
  const { onChangeText } = useOnChangeText()
  const { validationErrors, validate } = useValidation()

  const { data: graduationData } = useQuery(
    [`graduation-data`],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      return API.getGraduationsByFilterAPI({
        filter: {
          tenant: userDetail?.[0]?.tenantId ?? '',
        },
      })
    },
    {
      initialData: [],
      retry: false,
      onError: (error) => error,
    },
  )

  const { data: degreeData } = useQuery(
    [`degree-data`],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      const response = await API.getDegreesByFilterAPI({
        filter: {
          tenant: userDetail?.[0]?.tenantId ?? '',
        },
      })
      setFilterDegree(response)
      return response
    },
    {
      initialData: [],
      retry: false,
      onError: (error) => error,
    },
  )

  const { data: lookupData } = useQuery(
    [`course-lookup-Detail`],
    async () => {
      const response = await API.getCoursesByFilterAPI({ filter: {} })
      setFilterCourse(response)
      return response
    },
    {
      initialData: [],
      retry: false,
      onError: (error) => error,
    },
  )
  const { data: deparmentLookupData } = useQuery(
    [`department-lookup-Detail`],
    async () => {
      const tenant = await SecureStore.getCookie('user-details')
      const userDetail = JSON.parse(tenant?.userRoleDetails)
      return API.getDepartmentsByFilterAPI({
        filter: {
          tenant: userDetail?.[0]?.tenantId ?? '',
        },
      })
    },

    {
      initialData: [],
      retry: false,
      onError: (error) => error,
    },
  )
  const getLookupData = (fieldName) => {
    const options = []
    if (lookupData.length > 0 && ['courseName', 'course'].includes(fieldName)) {
      const filtered =
        filterCourse &&
        filterCourse?.filter(
          (item) => user?.department?.value === item?.department?.name,
        )
      filtered &&
        filtered?.map((item, i) => {
          options.push({
            displayName: item?.courseName,
            value: item?.courseName?.replace(/ /g, ''),
            id: item._id,
            displayOrder: i,
          })
          return null
        })
    } else if (deparmentLookupData.length > 0 && fieldName === 'department') {
      deparmentLookupData?.map((item, i) => {
        options.push({
          displayName: item?.name,
          value: item?.name?.replace(/ /g, ''),
          id: item._id,
          displayOrder: i,
        })
        return null
      })
    } else if (
      graduationData.length > 0 &&
      ['graduation'].includes(fieldName)
    ) {
      graduationData?.map((item, i) => {
        options.push({
          displayName: item?.name,
          value: item?.name?.replace(/ /g, ''),
          id: item._id,
          displayOrder: i,
        })
        return null
      })
    } else if (degreeData.length > 0 && fieldName === 'degree') {
      const filteredOptions =
        filterDegree &&
        filterDegree?.filter(
          (item) => user?.graduation?.value === item?.graduation?.name,
        )
      filteredOptions &&
        filteredOptions?.map((item, i) => {
          options.push({
            displayName: item?.name,
            value: item?.name.replace(/ /g, ''),
            id: item._id,
            displayOrder: i,
          })
          return null
        })
    }
    return options
  }

  const convertArrayToObject = (arr, title, arrayName) => {
    const isAdd = title?.[0]?.[0]?.metadata?.arrayName || arrayName
    let result = {}
    if (isAdd) {
      const editArray = []
      arr?.[isAdd]?.map((items) => {
        items?.map((item) => {
          if (item?.name !== 'subjectName') result[item?.name] = item?.value
          return null
        })
        editArray.push(result)
        result = {}
        return null
      })
      result[isAdd] = editArray
    } else {
      if (
        ['hobbies', 'extraCurricularActivities'].includes(
          title?.[0]?.[0]?.metadata?.fieldName,
        )
      ) {
        arr?.fields?.map((item) => {
          result[item?.name] = item?.value?.split(',')
          return null
        })
        return result
      }
      arr?.fields?.map((item) => {
        result[item?.name] = item?.value
        return null
      })
    }
    return result
  }
  const {
    data: userDetail,
    isFetching,
    refetch,
  } = useGetListing({
    module,
    id,
  })

  useEffect(() => {
    if (!userDetail) return
    getUserData(userDetail)
  }, [userDetail])

  const { mutateAsync: createUser } = useCreateUserMutation({
    module,
    setIsLoading,
  })

  const getUserData = (data) => {
    let arrayFieldValue = []
    const userValue = {}
    const checkValue = {}
    data?.sections?.map((section) => {
      section?.fields?.map((fields, ind) => {
        const mapValue = ['add-student', 'add-staff', 'add-course'].includes(
          currentRoute,
        )
          ? [fields]
          : fields
        mapValue?.map((field, i) => {
          if (
            field?.metadata?.arrayName !== '' &&
            field?.metadata?.arrayName !== null
          ) {
            arrayFieldValue.push({
              name:
                field?.metadata?.referenceName ?? field?.metadata?.fieldName,
              value: field?.value ?? '',
              metadata: field?.metadata?._id,
            })
            const fieldLength = [
              'add-student',
              'add-staff',
              'add-course',
            ].includes(currentRoute)
              ? section.fields.length - 1 === ind
              : fields.length - 1 === i
            if (fieldLength) {
              if (
                userValue[
                  field?.metadata?.referenceName ?? section.metadata.fieldName
                ] === undefined
              ) {
                userValue[
                  field?.metadata?.referenceName ?? section.metadata.fieldName
                ] = []
              }
              userValue[
                field?.metadata?.referenceName ?? section.metadata.fieldName
              ].push(arrayFieldValue)
              arrayFieldValue = []
            }
          } else if (field.metadata.valueType === 'chip') {
            userValue[
              field?.metadata?.referenceName ?? field.metadata.fieldName
            ] = {
              value: field.value,
              metadata: field.metadata._id,
            }
          } else if (field?.metadata?.component === 'PhoneNumberInput') {
            user.callingCode.value =
              field?.value?.split('-')[0] || user.callingCode.value
            userValue[
              field?.metadata?.referenceName ?? field.metadata.fieldName
            ] = {
              value: field?.value?.split('-')[1] || field?.value,
              metadata: field?.metadata?._id,
            }
          } else {
            userValue[
              field?.metadata?.referenceName ?? field.metadata.fieldName
            ] = {
              value: field.value,
              metadata: field.metadata._id,
            }
            if (field.metadata.valueType === 'CheckBox') {
              checkValue[field?.metadata?.displayOrder] = field?.value === 'Yes'
            }
          }
          return null
        })
        return null
      })
      return null
    })
    setChecked(checkValue)
    setUser({ ...user, ...userValue })
  }

  const handleSubmit = (data, isUserInvite) => {
    data?.sections?.map((item) =>
      validate({
        key: item?.metadata?.displayName,
        userData: user,
        dataFields: [item?.fields],
      }),
    )

    setErrors(validationErrors)

    if (validationErrors.length > 0) {
      return
    }
    setIsLoading(true)
    createUser({ details: updateValue, invite: isUserInvite, domainDetails })
  }

  const selectedTabData = userDetail?.tabs?.find(
    (item) => item.metadata.displayOrder === tabSelect,
  )?.metadata

  const selectedTabDisplayName = selectedTabData?.displayName
  const selectedTabFieldName = selectedTabData?.fieldName

  const handleTabChange = (item) => {
    setTabSelect(
      userDetail?.tabs?.find((ite) => ite?.metadata?.displayName === item)
        .metadata.displayOrder,
    )
  }

  const tabData = userDetail?.tabs?.map((item) => item.metadata.displayName)

  tabData?.sort((a, b) => {
    const displayOrderA =
      userDetail.tabs?.find((item) => item.metadata.displayName === a)?.metadata
        .displayOrder || 0
    const displayOrderB =
      userDetail.tabs?.find((item) => item.metadata.displayName === b)?.metadata
        .displayOrder || 0
    return displayOrderA - displayOrderB
  })

  const section = userDetail?.sections?.find(
    (item) => selectedTabDisplayName === item?.metadata?.displayName,
  )?.section

  const onChangeTextLocal = (data, text) => {
    onChangeText({
      data,
      text,
      isAdd: !!addSection,
      user,
      updateValue,
      setUser,
      setUpdateValue,
      editSection,
      module,
    })
  }
  const clearEditSelection = () => {
    setEditSection('')
    setAddSection('')
    setUpdateValue({
      fields: [],
    })
    getUserData(userDetail)
  }
  const handleAddNew = (mainKey, mainValue, localSelectedSem) => {
    const add = []
    const userField = []

    // Find courseDuration and fields
    const courseDuration = userDetail?.sections?.find(
      (item) => item?.metadata?.fieldName === mainValue?.metadata?.fieldName,
    )?.fields
    const fields = Array.isArray(courseDuration[0])
      ? courseDuration?.[0]
      : courseDuration

    // Populate 'add' array
    user?.[mainValue?.metadata?.fieldName][0]?.map((item) => {
      if (item.name === 'semester') {
        add.push({
          value: localSelectedSem.toString(),
          metadata: item?.metadata,
          name: item?.name,
        })
      } else {
        add.push({ value: '', metadata: item.metadata, name: item.name })
      }
      return null
    })

    // Populate 'userField' array
    fields.map((item) => {
      if (item.metadata.fieldName === 'semester') {
        userField.push({
          value: localSelectedSem.toString(),
          permissions: item.permissions,
          metadata: item.metadata,
        })
      } else {
        userField.push(item)
      }
      return null
    })

    // Check and update 'val'
    let val

    user?.[mainValue?.metadata?.fieldName]?.map((items) => {
      val = items.find((item) => item?.name === 'semester')?.value
      return null
    })

    if (val === '') {
      setUser((userData) => {
        const prevUser = { ...userData }
        prevUser[mainValue?.metadata?.fieldName] = []
        return prevUser
      })
    }
    const data = userDetail?.sections?.find(
      (item) => item?.metadata?.fieldName === mainValue?.metadata?.fieldName,
    ).fields
    if (Array.isArray(courseDuration[0])) {
      courseDuration?.map((items) => {
        val = items.find(
          (item) => item?.metadata?.fieldName === 'semester',
        )?.value
        return null
      })
    } else {
      val = courseDuration?.find(
        (items) => items?.metadata?.fieldName === 'semester',
      )?.value
    }
    if (val === '' || !val) {
      userDetail?.sections
        ?.find(
          (item) =>
            item?.metadata?.fieldName === mainValue?.metadata?.fieldName,
        )
        .fields.splice(0, data.length)
    }
    userDetail?.sections
      ?.find(
        (item) => item?.metadata?.fieldName === mainValue?.metadata?.fieldName,
      )
      .fields.push(userField)

    // Update 'user' with 'add'
    setUser((userData) => {
      const prevUser = { ...userData }
      prevUser[mainValue?.metadata?.fieldName] = [
        ...prevUser[mainValue?.metadata?.fieldName],
        add,
      ]
      return prevUser
    })
  }
  const handleAddSection = (mainValue) => {
    mainValue?.fields.push(mainValue?.fields[0])
    const add = []
    user[mainValue?.metadata?.fieldName][0]?.map((item) => {
      add.push({ value: '', metadata: item.metadata, name: item.name })
      return null
    })
    setUser((userData) => {
      const prevUser = { ...userData }
      prevUser[mainValue?.metadata?.fieldName] = [
        ...prevUser[mainValue?.metadata?.fieldName],
        add,
      ]
      return prevUser
    })
  }
  const handleDelete = (index, mainValue) => {
    const fields = userDetail?.sections?.find(
      (item) => item?.name === mainValue?.metadata?.fieldName,
    ).fields
    fields.splice(index, 1)
    const valueField = user?.[mainValue?.metadata?.fieldName]
    valueField.splice(index, 1)
    setUser((userData) => {
      const prevUser = { ...userData }
      prevUser[mainValue?.metadata?.fieldName] = [...valueField]
      return prevUser
    })
  }

  const handleEdit = async (keys, values, isAdd, localSelectedSem) => {
    if (isAdd) {
      const add = []
      user[values?.metadata?.fieldName]?.[0]?.map((item) => {
        if (item.name === 'semester') {
          add.push({
            value: localSelectedSem.toString(),
            metadata: item.metadata,
            name: item.name,
          })
        } else {
          add.push({ value: '', metadata: item.metadata, name: item.name })
        }
        return null
      })
      setUpdateValue({ [values?.metadata?.fieldName]: [add] })
      if (keys !== 'Section') {
        setAddSection(keys)
        setEditSection('')
      }
    } else {
      setAddSection('')
      setEditSection(keys)
    }
  }

  const handleOnSave = async (title, keys, metadata) => {
    // Perform validation based on selected sections
    Object.entries(
      groupMetaData(userDetail)[selectedTabFieldName] ||
        groupMetaData(userDetail)[''],
    ).map(([itemKey, itemValue]) => {
      if (
        itemKey.toLowerCase() === editSection.toLowerCase() ||
        itemKey.toLowerCase() === addSection.toLowerCase()
      ) {
        validate({
          key: itemKey,
          userData: user,
          dataFields: itemValue?.fields,
        })
      }
      return null
    })
    // Set validation errors and return if errors exist
    setErrors(validationErrors)
    const arrayName = title?.[0]?.[0]?.metadata?.arrayName
    if (
      validationErrors?.length > 0 ||
      (updateValue.fields.length === 0 && !updateValue?.[arrayName])
    ) {
      return
    }
    setIsLoading(true)
    if (title === 'hobbies' || title === 'extraCurricularActivities') {
      const filteredKeys = user?.[metadata.fieldName]?.value?.filter(
        (subArr) =>
          subArr?.replace(/[A-Z]/g, ' $&')?.trim() !==
          keys?.replace(/[A-Z]/g, ' $&')?.trim(),
      )

      const fieldUpdate = {
        name: metadata.fieldName,
        metadata: metadata._id,
        value: filteredKeys,
      }

      user[metadata.fieldName] = {
        metadata: metadata._id,
        value: filteredKeys,
      }

      setUpdateValue((prevValue) => ({
        ...prevValue,
        fields: [fieldUpdate],
      }))

      handleSave(getInvite(false, id, { fields: [fieldUpdate] }, title))
    } else {
      handleSave(getInvite(false, id, updateValue, title))
    }
  }
  const getInvite = (invite, localId, updVal, title) => {
    if (module === 'student' || module === 'studentProfile') {
      return {
        id: localId,
        data: {
          student: {
            ...convertArrayToObject(updVal, title),
          },
        },
        arrayName: '',
      }
    }
    if (
      module === 'staff' ||
      module === 'staffProfile' ||
      module === 'adminProfile'
    ) {
      return {
        id: localId,
        data: {
          staff: {
            ...convertArrayToObject(updVal, title),
          },
        },
        arrayName: '',
      }
    }
    if (module === 'viewCourse') {
      if (title?.[0]?.[0]?.metadata?.arrayName === 'subjectDetails') {
        return {
          data: {
            id: localId,
            subject: { ...convertArrayToObject(updVal, title) },
          },
          arrayName: title?.[0]?.[0]?.metadata?.arrayName,
        }
      }
      return {
        data: {
          id: localId,
          course: { ...convertArrayToObject(updVal, title) },
        },
        arrayName: '',
      }
    }
    if (module === 'viewSyllabus') {
      return {
        data: {
          ...convertArrayToObject(updVal, title),
        },
      }
    }
    if (module === 'campus-configuration') {
      return {
        data: {
          ...convertArrayToObject(updVal, title),
        },
      }
    }
  }
  const handleEditQuestionPaper = ({ cleanedData, question }) => {
    const { courseName, subjectName, difficultyLevel } = JSON.parse(
      question?.generatedQuestion || '{}',
    )
    let sections = []
    Object.entries(cleanedData).map(([, value]) => {
      sections.push({
        sectionName: value.sectionName,
        sectionType: value.sectionType,
        questions: value.questions,
      })
    })
    const generatedQuestion = JSON.stringify({
      courseName,
      difficultyLevel,
      sections,
      subjectName,
    })
    const final = {
      date: question.date,
      difficultyLevel: question.difficultyLevel,
      examName: question.examName,
      noOfSections: question.noOfSections,
      questionSections: question.questionPaperSection,
      totalMarks: question.totalMarks,
      id: question._id,
      timeLimit: question.timeLimit,
      generatedQuestion,
    }
    handleSave({ data: final, isUpdate: true })
    return final
  }

  const { mutate: handleSave } = useUpdateUserDetails({
    module,
    userId: id,
    onSuccess: () => {
      refetch()
      setUpdateValue({})
      clearEditSelection()
    },
    onError: () => {
      setUpdateValue({})
      clearEditSelection()
    },
    setIsLoading,
  })

  const generateQuestion = () => {
    Object.entries(
      groupMetaData(userDetail)[selectedTabFieldName] ||
        groupMetaData(userDetail)[''],
    ).map(([itemKey, itemValue]) => {
      if (itemKey.toLowerCase() !== 'question paper preview') {
        validate({
          key: itemKey,
          userData: user,
          dataFields: itemValue?.fields,
        })
      }
      return null
    })

    // Set validation errors and return if errors exist
    setErrors(validationErrors)

    if (selectedTopics?.length === 0)
      setSelectedUnitsValidation('Units are mandatory')

    if (validationErrors?.length > 0 || selectedTopics?.length === 0) {
      return
    }

    const general = convertArrayToObject(updateValue)
    const questionSection = convertArrayToObject(
      updateValue,
      '',
      'questionPaperSection',
    )
    setselectedTopics((previousTopic) => {
      const updatedSelectedTopics = [...previousTopic]
      updatedSelectedTopics?.map((topic) => {
        topic.topics = topic?.topics?.join(',')
        return null
      })
      return updatedSelectedTopics
    })
    setIsLoading(true)
    handleSave({
      data: {
        ...general,
        ...questionSection,
        units: selectedTopics,
        subjectName: firstName,
      },
      isAdd: true,
    })
  }

  const handleCnacelGenerateQuestion = (mainValue) => {
    mainValue = [mainValue[0]]
    const arrayName = mainValue[0][0]?.metadata?.arrayName
    const userDetails = userDetail?.sections?.find((item) => {
      return arrayName === item?.metadata?.fieldName
    })
    userDetails.fields = mainValue
    setUser({
      countryCode: { value: 'IN', metadata: '' },
      callingCode: { value: '+91', metadata: '' },
    })
    setselectedTopics([])
    setErrors([])
    setSelectedUnitsValidation('')
    return userDetails
  }

  const create = async (mainValue, localAddSection) => {
    Object.entries(
      groupMetaData(userDetail)[selectedTabFieldName] ||
        groupMetaData(userDetail)[''],
    ).map(([itemKey, itemValue]) => {
      if (
        itemKey.toLowerCase() === editSection.toLowerCase() ||
        itemKey.toLowerCase() === localAddSection.toLowerCase()
      ) {
        validate({
          isCreate: true,
          key: itemKey,
          userData: updateValue,
          dataFields: itemValue?.fields,
        })
      }
      return null
    })
    setErrors(validationErrors)
    if (validationErrors?.length > 0) {
      return
    }
    setIsLoading(true)
    const oldValue = mainValue?.map((group) => {
      const count = group?.filter((val) => val?.value === '')
      if (count.length !== group?.length) {
        return group?.map((item) => ({
          value: item?.value, // Replace with the desired value
          metadata: item?.metadata?._id,
          name: item?.metadata?.fieldName,
        }))
      }
      return null
    })
    const { arrayName } = mainValue[0][0].metadata
    if (
      mainValue?.[0]?.[0]?.metadata?.fieldName === 'hobbies' ||
      mainValue?.[0]?.[0]?.metadata?.fieldName === 'extraCurricularActivities'
    ) {
      updateValue?.[arrayName]?.map((item) => {
        oldValue?.[0]?.[0].value.push(item[0].value)
        if (module === 'student' || module === 'studentProfile') {
          handleSave({
            id,
            data: {
              student: {
                ...convertArrayToObject(
                  { [arrayName]: oldValue },
                  '',
                  arrayName,
                )?.[arrayName]?.[0],
              },
            },
          })
        }
        return null
      })

      return
    }
    const filteredValue = oldValue?.filter((subarray) =>
      subarray?.some((item) => item?.value !== undefined),
    )
    const newValue = []
    updateValue?.[arrayName]?.map((item) => {
      newValue?.push(item)
      return null
    })
    filteredValue?.push(...newValue)
    const finalValue = { [arrayName]: filteredValue }
    if (
      module === 'staff' ||
      module === 'staffProfile' ||
      module === 'adminProfile'
    ) {
      handleSave({
        id,
        data: {
          staff: {
            ...convertArrayToObject(finalValue, '', arrayName),
          },
        },
      })
    } else if (module === 'student' || module === 'studentProfile') {
      handleSave({
        id,
        data: {
          student: {
            ...convertArrayToObject(finalValue, '', arrayName),
          },
        },
      })
    } else if (module === 'viewCourse') {
      handleSave({
        data: {
          ...updateValue,
        },
        domainDetails,
        arrayName,
        isAdd: true,
      })
    } else if (module === 'viewSyllabus') {
      handleSave({
        data: {
          ...convertArrayToObject(finalValue, '', arrayName),
        },
        arrayName,
      })
    }
  }

  const viewProps = {
    updateValue,
    addSection,
    attachment,
    checked,
    clearEditSelection,
    countryCode: user?.countryCode?.value,
    create,
    currentRoute,
    detailsRef,
    editSection,
    errors,
    fileInputRef,
    firstName,
    generateQuestion,
    getLookupData,
    handleAddImage,
    handleEdit,
    handleEditQuestionPaper,
    handleOnSave,
    handleSubmit,
    handleTabChange,
    handleAddSection,
    isFetching,
    isLoading,
    module,
    onChangeText: onChangeTextLocal,
    onUpload,
    scrollToTop,
    section,
    selectedTabDisplayName,
    selectedTabFieldName,
    setChecked,
    setEditSection,
    setErrors,
    setTabSelect,
    setUser,
    showSideBar,
    tabData,
    handleAddNew,
    handleDelete,
    user,
    userDetail,
    id,
    pickPDF,
    handleFileChange,
    pdfInputRef,
    selectedTopics,
    setselectedTopics,
    selectedSem,
    setSelectedSem,
    setUser,
    selectedUnitsValidation,
    setSelectedUnitsValidation,
    handleCnacelGenerateQuestion,
    getUserData,
  }

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  return (
    <Suspense fallback={<Text>{t('Directory.LOADING')}</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ViewDetails
